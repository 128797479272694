















































































import BrandLogo from '@/components/branding/BrandLogo.vue'
import { SiteHeaderItemConfig } from '@/composition/siteHeader'
import { formatPhoneNumber } from '@/utils/phone'
import { currentRoute } from '@/router'
import { title, socials, address, phone, email } from '@/app.config.json'
import { computed, defineComponent, PropType, ref } from '@vue/composition-api'

export type SiteHeaderMode = 'static' | 'sticky' | 'fixed'

export default defineComponent({
  name: 'SiteHeader',

  components: {
    BrandLogo,
  },

  props: {
    mode: {
      type: String as PropType<SiteHeaderMode>,
      default: 'sticky' as SiteHeaderMode,
    },

    items: {
      type: Array as PropType<SiteHeaderItemConfig[]>,
      required: true,
    },
  },

  setup() {
    const isMobileMenuOpen = ref(false)
    const isMenuOpen = ref(false)
    const isRouteHome = computed(() => currentRoute.value?.fullPath === '/')

    function closeMenu(): void {
      if (!isMobileMenuOpen.value) return
      isMobileMenuOpen.value = false
    }

    function toggleMenu(): void {
      isMenuOpen.value = !isMenuOpen.value
    }

    function close() {
      isMenuOpen.value = false
    }

    return {
      title,
      socials,
      address,
      phone,
      formattedPhone: formatPhoneNumber(phone),
      email,
      isMobileMenuOpen,
      closeMenu,
      toggleMenu,
      isMenuOpen,
      close,
      isRouteHome,
    }
  },
})
