interface CardConfig {
  key: string
  imgUrl: string
  src?: string
  routeName: string
}

export const cards: CardConfig[] = [
  {
    key: 'poultry',
    imgUrl: '@/assets/images/cards/poultry-programs.png',
    src: 'https://adisseovirtualna.info/poultry?utm_source=kiosk',
    routeName: 'Poultry',
  },
  // {
  //   key: 'dairy',
  //   imgUrl: '@/assets/images/cards/dairy-programs.png',
  //   src: 'https://adisseovirtualna.info/dairy?utm_source=kiosk',
  //   routeName: 'Dairy',
  // },
  {
    key: 'swine',
    imgUrl: '@/assets/images/cards/swine-programs.png',
    src: 'https://adisseovirtualna.info/swine?utm_source=kiosk',
    routeName: 'Swine',
  },
  {
    key: 'selisseo',
    imgUrl: '@/assets/images/cards/selisseo.png',
    src: 'https://selisseo.mrcodigo.com?utm_source=kiosk',
    routeName: 'Selisseo',
  },
  {
    key: 'milkpay',
    imgUrl: '@/assets/images/cards/milk-pay.png',
    src: 'https://milkpay.com', // https://apps.apple.com/us/app/milkpay/id563621014
    routeName: 'MilkPay',
  },
  {
    key: 'mycoman',
    imgUrl: '@/assets/images/cards/myco-man.png',
    src: 'https://apps.apple.com/us/app/mycoman/id1112165423',
    routeName: 'MycoMan',
  },
  {
    key: 'milksmart',
    imgUrl: '@/assets/images/cards/milk-smart.png',
    src: 'https://apps.apple.com/us/app/milksmart/id1409980252',
    routeName: 'MilkSmart',
  },
  // {
  //   key: 'palatability',
  //   imgUrl: '@/assets/images/cards/palatability.png',
  //   src: 'http://www.kazting-web.com?utm_source=kiosk',
  //   routeName: 'Palatability',
  // },
  // {
  //   key: 'files',
  //   imgUrl: '@/assets/images/cards/files.png',
  //   routeName: 'Files',
  // },
]

export const cardsWithSrc = cards.filter((card) => Boolean(card.src))
