



























import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'BaseBanner',

  props: {
    tag: {
      type: String,
      default: 'div',
    },
  },

  emits: {
    close: null,
  },

  setup(props, context) {
    function close() {
      context.emit('close')
    }

    return {
      close,
    }
  },
})
