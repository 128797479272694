import { FontResource } from '.'

const resources: FontResource[] = [
  {
    family: 'Exo',
    source: '/fonts/Exo-Black.ttf',
    format: 'truetype',
    descriptors: {
      style: 'normal',
      weight: 900,
    },
  },
  {
    family: 'Exo',
    source: '/fonts/Exo-BlackItalic.ttf',
    format: 'truetype',
    descriptors: {
      style: 'italic',
      weight: 900,
    },
  },
  {
    family: 'Exo',
    source: '/fonts/Exo-ExtraBold.ttf',
    format: 'truetype',
    descriptors: {
      style: 'normal',
      weight: 800,
    },
  },
  {
    family: 'Exo',
    source: '/fonts/Exo-ExtraBoldItalic.ttf',
    format: 'truetype',
    descriptors: {
      style: 'italic',
      weight: 800,
    },
  },
  {
    family: 'Exo',
    source: '/fonts/Exo-Bold.ttf',
    format: 'truetype',
    descriptors: {
      style: 'normal',
      weight: 700,
    },
  },
  {
    family: 'Exo',
    source: '/fonts/Exo-BoldItalic.ttf',
    format: 'truetype',
    descriptors: {
      style: 'italic',
      weight: 700,
    },
  },
  {
    family: 'Exo',
    source: '/fonts/Exo-SemiBold.ttf',
    format: 'truetype',
    descriptors: {
      style: 'normal',
      weight: 600,
    },
  },
  {
    family: 'Exo',
    source: '/fonts/Exo-SemiBoldItalic.ttf',
    format: 'truetype',
    descriptors: {
      style: 'italic',
      weight: 600,
    },
  },
  {
    family: 'Exo',
    source: '/fonts/Exo-Medium.ttf',
    format: 'truetype',
    descriptors: {
      style: 'normal',
      weight: 500,
    },
  },
  {
    family: 'Exo',
    source: '/fonts/Exo-MediumItalic.ttf',
    format: 'truetype',
    descriptors: {
      style: 'italic',
      weight: 500,
    },
  },
  {
    family: 'Exo',
    source: '/fonts/Exo-Regular.ttf',
    format: 'truetype',
    descriptors: {
      style: 'normal',
      weight: 400,
    },
  },
  {
    family: 'Exo',
    source: '/fonts/Exo-Italic.ttf',
    format: 'truetype',
    descriptors: {
      style: 'italic',
      weight: 400,
    },
  },
  {
    family: 'Exo',
    source: '/fonts/Exo-Light.ttf',
    format: 'truetype',
    descriptors: {
      style: 'normal',
      weight: 300,
    },
  },
  {
    family: 'Exo',
    source: '/fonts/Exo-LightItalic.ttf',
    format: 'truetype',
    descriptors: {
      style: 'italic',
      weight: 300,
    },
  },
  {
    family: 'Exo',
    source: '/fonts/Exo-ExtraLight.ttf',
    format: 'truetype',
    descriptors: {
      style: 'normal',
      weight: 200,
    },
  },
  {
    family: 'Exo',
    source: '/fonts/Exo-ExtraLightItalic.ttf',
    format: 'truetype',
    descriptors: {
      style: 'italic',
      weight: 200,
    },
  },
  {
    family: 'Exo',
    source: '/fonts/Exo-Thin.ttf',
    format: 'truetype',
    descriptors: {
      style: 'normal',
      weight: 100,
    },
  },
  {
    family: 'Exo',
    source: '/fonts/Exo-ThinItalic.ttf',
    format: 'truetype',
    descriptors: {
      style: 'italic',
      weight: 100,
    },
  },
]

export default resources
