import { auth } from '@/composition/auth'
import NProgress from '@/setup/nprogress'
import { cardsWithSrc } from '@/utils/appCards'
import { AppRouteConfig } from '.'

const routes: AppRouteConfig[] = [
  {
    path: '/',
    name: 'Home',
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'Home.vue' */
          '@/views/Home.vue'
        )
      ),
    meta: {
      i18n: 'routes.home',
    },
  },
  ...cardsWithSrc.map<AppRouteConfig>((card) => ({
    path: `/${card.key}`,
    name: card.routeName,
    beforeEnter(routeTo, routeFrom, next) {
      if (card.src && card.src.startsWith('https://apps.apple.com')) {
        // Complete the animation of the route progress bar after route cancel
        window.location.replace(card.src)
        next(false)
        NProgress.done()

        // window.location.replace(`${card.key}://`)

        // setTimeout(() => {
        //   if (card.src) {
        //     window.location.replace(card.src)
        //   }
        //   next(false)
        // }, 2000)
      } else {
        next()
      }
    },
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'AppViewer.vue' */
          '@/views/AppViewer.vue'
        )
      ),
    props: () => ({
      iframeSrc: card.src,
    }),
  })),
  {
    path: '/files',
    name: 'Files',
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'Files.vue' */
          '@/views/Files.vue'
        )
      ),
    meta: {
      i18n: 'routes.files',
    },
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'Calendar.vue' */
          '@/views/Calendar.vue'
        )
      ),
    meta: {
      i18n: 'routes.calendar',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'Login.vue' */
          '@/views/Login.vue'
        )
      ),
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (auth.loggedIn.value) {
        // Redirect to the home page instead
        next({ name: 'Home' })
      } else {
        // Continue to the login page
        next()
      }
    },
    meta: {
      i18n: 'routes.login',
      layout: 'LayoutCommon',
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter(routeTo, routeFrom, next) {
      auth.logout()
      // Navigate back to login
      next({ name: 'Login' })
    },
    meta: {
      i18n: 'routes.logout',
    },
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'Forbidden.vue' */
          '@/views/Forbidden.vue'
        )
      ),
    meta: {
      i18n: 'routes.forbidden',
    },
  },
  {
    path: '/not-found',
    name: 'ErrorNotFound',
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: 'ErrorNotFound.vue' */
          '@/views/ErrorNotFound.vue'
        )
      ),
    meta: {
      i18n: 'routes.errorNotFound',
    },
  },
  {
    path: '*',
    redirect: {
      name: 'Home',
      params: {
        resource: '',
      },
    },
  },
]

export default routes

//===
// Private helpers
//===

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@/views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@/views/my-view')
//
function lazyLoadView(AsyncView: Promise<any>) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require('@/views/states/Loading.vue').default,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require('@/views/states/Timeout.vue').default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h: any, { data, children }: any) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}
