






import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  name: 'BaseNotice',

  props: {
    tag: {
      type: String,
      default: 'div',
    },

    tone: {
      type: String,
      default: 'neutral',
    },
  },

  setup(props) {
    const computedClass = computed(() => [
      'BaseNotice',
      `BaseNotice--tone-${props.tone}`,
    ])

    return {
      computedClass,
    }
  },
})
