





















































// import SiteLanguageLinks from '@/components/SiteLanguageLinks.vue'
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SiteFooter',

  components: {
    // SiteLanguageLinks,
  },

  setup() {
    const currentYear = computed(() => new Date().getUTCFullYear())

    return {
      currentYear,
    }
  },
})
