export interface IppeTree {
  id: number
  name: string
  contents: IppeTreeItem[]
  type: IppeTreeItemType
  parentItem?: IppeTree
  requireFile?: () => string
}

let uuid = 0

export interface IppeTreeItem extends IppeTree {}

export type IppeTreeItemType = 'root' | 'folder' | 'file'

const requireIppeFilesTree = require.context('@/assets/ippe/files', true)

export const ippeTree: IppeTree = {
  id: uuid++,
  name: 'Root',
  contents: [],
  type: 'root',
}

export const fileTreeItemList: IppeTreeItem[] = []

const pathDictionary: Record<string, IppeTreeItem> = {}

requireIppeFilesTree.keys().forEach((path: string) => {
  const cleanPath = path
    // Remove the "./" from the beginning
    .replace(/^\.\//, '')

  // The last chunk will be the file name with extension,
  // the rest are directories or nested directories
  const pathTree = cleanPath.split('/')
  const fileName = pathTree.pop()

  const requireFile = () => requireIppeFilesTree(path).default

  let parentFolder: IppeTreeItem = ippeTree
  let traversedPath = ''

  for (let index = 0; index < pathTree.length; index++) {
    const folderName = pathTree[index]
    traversedPath = traversedPath
      ? `${traversedPath}/${folderName}`
      : folderName

    if (pathDictionary[traversedPath]) {
      parentFolder = pathDictionary[traversedPath]
      continue
    }

    const folder: IppeTree = {
      id: uuid++,
      name: folderName,
      contents: [],
      type: 'folder',
      parentItem: parentFolder,
    }
    parentFolder.contents.push(folder)
    parentFolder = folder

    pathDictionary[traversedPath] = folder
  }

  if (fileName) {
    const fileItem: IppeTreeItem = {
      id: uuid++,
      name: fileName,
      contents: [],
      type: 'file',
      requireFile,
    }
    parentFolder.contents.push(fileItem)
    fileTreeItemList.push(fileItem)
  }
})
