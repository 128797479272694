









































































































import { ValidationProvider } from 'vee-validate'
import Vue from 'vue'

export default Vue.extend({
  name: 'BaseField',

  components: {
    ValidationProvider,
  },

  inheritAttrs: false,

  props: {
    icon: {
      type: String,
      default: null,
    },

    iconRight: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: '',
    },

    labelRight: {
      type: Boolean,
      default: false,
    },

    labelPrefix: {
      type: String,
      default: '',
    },

    labelSuffix: {
      type: String,
      default: '',
    },

    vertical: {
      type: Boolean,
      default: false,
    },

    center: {
      type: Boolean,
      default: false,
    },

    hint: {
      type: String,
      default: '',
    },

    hintIcon: {
      type: String,
      default: '',
    },

    errorIcon: {
      type: String,
      default: 'exclamation-circle',
    },

    errorOverlay: {
      type: Boolean,
      default: false,
    },

    iconColor: {
      type: String,
      default: 'var(--theme-color-secondary)',
    },

    fullWidthControl: {
      type: Boolean,
      default: false,
    },

    vid: {
      type: String,
      default: '',
    },

    mode: {
      type: String,
      default: 'eager',
    },

    name: {
      type: String,
      default: '',
    },

    rules: {
      type: [String, Object],
      default: '',
    },
  },

  computed: {
    labelClass(): (string | { [key: string]: boolean })[] {
      return [
        'BaseField__label',
        {
          'BaseField__label--vertical': this.vertical,
          'BaseField__label--prefixed': Boolean(this.labelPrefix),
          'BaseField__label--suffixed': Boolean(this.labelSuffix),
        },
      ]
    },
  },

  methods: {
    getIconFill(hasError: boolean): string {
      return hasError ? 'var(--theme-color-danger)' : this.iconColor
    },
  },
})
